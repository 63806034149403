import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from "@elastic/eui";
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Recording/Snapshot_Schedule/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import ScheduleTable from 'components/Web_User_Interface/1440p_Series/Recording/Snapshot_Schedule/photoSheduleTable';
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "Recording Menu // Snapshot Schedule",
  "path": "/Web_User_Interface/1440p_Series/Recording/Snapshot_Schedule/",
  "dateChanged": "2024-09-19",
  "author": "Mike Polinowski",
  "excerpt": "Set a schedule for snapshot recording for days of the week and time of the day. Record those snapshots to the internal SD card, send them by email or upload them to an FTP server.",
  "image": "./WebUI_1440p_SearchThumb_Tasks_Photoseries.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title="Recording Menu // Snapshot Schedule" dateChanged="2024-09-19" author="Mike Polinowski" tag="INSTAR IP Camera" description="Set a schedule for snapshot recording for days of the week and time of the day. Record those snapshots to the internal SD card, send them by email or upload them to an FTP server." image="/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.png" twitter="/images/Search/WebUI_1440p_SearchThumb_Tasks_Photoseries.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Web_User_Interface/1440p_Serie/Aufnahme_Aufgaben/Snapshot_Zeitplan/" locationFR="/fr/Web_User_Interface/1440p_Series/Recording/Snapshot_Schedule/" crumbLabel="Snapshot Schedule" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "task-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#task-menu",
        "aria-label": "task menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Task Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "configure-a-snapshot-recording-schedule-on-sd-card-by-email-or-for-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-a-snapshot-recording-schedule-on-sd-card-by-email-or-for-ftp-server",
        "aria-label": "configure a snapshot recording schedule on sd card by email or for ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure a Snapshot Recording Schedule on SD Card, by Email or for FTP Server`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/47aef/1440p_Settings_Task_Snap_Schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACJ0lEQVQoz02Py2sTURjF819km6qdSXJnwNo8lGTunVcyj2SSmXtnkkk6o/Zh0jZEsGKCkjYWBSukCTYL22LahbVE0IWF6kIQXCgI7vQP0lQrHj4+zubHOSfAMAzDshHqYsqYz7eOxMau1NznV/fgyh5c2T37E5O9va/dGbFJXlezodBUMBgMTYUCAACGYalLFwRrwe++ctovyvcP7PbIbh/8f+6DQ3/jZUouIghZ9jIAbDQKJjAAgKbp1LWkY+pe2Zr3ShW7iA3FLupWXrHyCjZUM5cxc7KhYgHJAi+LghKPXf0DMzRNpznOxMSycKU6V3YrmBBMbExsQhxCbOK4tlermqWcJom8CDlpdjYxgaPR6DRFIYQG24PhcOj7PiHE87xqteqWnEI+p+Z0xSjoRaexaLgODzmeR3I8lvwLUxTFIa7erC/Xlx3HsSxrElxyHf8Wri74Jl4sFD3TbNZUK8fxUMiIYiKeCDAMAwCgpikoQVLDhUKBEEJs28Ymnlsy157rjV5bzg+RvJbRnrVW3bwoIpSVpXjsvHY4HL4yMwPTSMmqmqppmi5nVE03mrWbG93OVr+32WltrndOB9tNonEQSoIU+7c5EonQNE2Hw+kU4tKCLEjXsX74tPXt/fjN+PXP75+/vjv+8fHkw2ine0NVMiJCUix2XjtyJgCYVErQJGmJqA/rZLx198vJ0afTtzuP1/vdzrj/5Lj36F45UzYVyAm/k38BY7PAtaGnLIoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1fdb5564087e8bc1a2687500f2afbc9/e4706/1440p_Settings_Task_Snap_Schedule.avif 230w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/d1af7/1440p_Settings_Task_Snap_Schedule.avif 460w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/7f308/1440p_Settings_Task_Snap_Schedule.avif 920w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/29832/1440p_Settings_Task_Snap_Schedule.avif 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e1fdb5564087e8bc1a2687500f2afbc9/a0b58/1440p_Settings_Task_Snap_Schedule.webp 230w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/bc10c/1440p_Settings_Task_Snap_Schedule.webp 460w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/966d8/1440p_Settings_Task_Snap_Schedule.webp 920w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/0ca5b/1440p_Settings_Task_Snap_Schedule.webp 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1fdb5564087e8bc1a2687500f2afbc9/81c8e/1440p_Settings_Task_Snap_Schedule.png 230w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/08a84/1440p_Settings_Task_Snap_Schedule.png 460w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/c0255/1440p_Settings_Task_Snap_Schedule.png 920w", "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/47aef/1440p_Settings_Task_Snap_Schedule.png 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e1fdb5564087e8bc1a2687500f2afbc9/c0255/1440p_Settings_Task_Snap_Schedule.png",
              "alt": "Web User Interface - 1440p Series - Recording",
              "title": "Web User Interface - 1440p Series - Recording",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ScheduleTable mdxType="ScheduleTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      